$(document).ready(function() {

    /*******************************************/
    //Form Validation
    $('.validate-form').each(function() {
        $(this).validate({
            submitHandler: function(form) {
                form.submit();
            },
            rules: {
                required: true
            },
            errorPlacement: function(error, element) {
                error.appendTo(element.parents('.form-field'));
            },
            highlight: function(element, errorClass, validClass) {
                if (element.type === 'radio') {
                    this.findByName(element.name).parents('.form-field').addClass(errorClass).removeClass(validClass);
                } else {
                    $(element).parents('.form-field').addClass(errorClass).removeClass(validClass);
                }
            },
            unhighlight: function(element, errorClass, validClass) {
                if (element.type === 'radio') {
                    this.findByName(element.name).parents('.form-field').removeClass(errorClass).addClass(validClass);
                } else {
                    $(element).parents('.form-field').removeClass(errorClass).addClass(validClass);
                }
            }
        });
    });

    $( ".date-picker" ).datepicker();

    /*******************************************/
    //Custom Select Dropdown 
    $(function() {
        $('.custom-select-dropdown').selectric();
        $('.select2').select2();
    });

    $(window).resize(function() {
        $('.select2').width('100%');
    });

    /*******************************************/
    //Lightbox Initiations
    $('.open-popup-link').magnificPopup({
        type:'image',
        showCloseBtn: false,
        closeOnBgClick: true,
        removalDelay: 300,
        mainClass: 'mfp-zoom-in',
        midClick: true
    });

    // $('.image').each(function() {
    //     $(this).magnificPopup({
    //         delegate: 'a',
    //         type: 'image',
    //         gallery: {
    //           enabled: true
    //       }
    //   });
    // });

    var magnificPopup = $.magnificPopup.instance;
    $('.images').magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            arrowMarkup: '',
            tCounter: '',
            titleSrc: function(item) {
                return item.el.attr('title');
            }
        },
        callbacks: {
            open: function() {
                $('.mfp-arrows, .mfp-close').toggle();
                $('.mfp-content').append('<div class="mfp-close-custom">Close</div>');
                // $('.mfp-container').append(
                //     '<div class="mfp-arrows mfp-arrow-left" data-step="prev"><img src="/images/mfp-arrow.svg"></div>'+
                //     '<div class="mfp-arrows mfp-arrow-right" data-step="next"><img src="/images/mfp-arrow.svg"></div>'
                //     );
                $('.mfp-close-custom').toggle();            
            },
            close: function() {
                $('.mfp-arrows, .mfp-close').toggle();
            },
            imageLoadComplete: function() {
                if (window.innerWidth <= 450) {
                    $('.mfp-content').css('width', '280px');
                }
            }
        }
    });

    $('.mfp-arrows').on('click', function() {
        // console.log('clicked', $(this).data('step'));
        if ($(this).data('step') == 'next') {
            magnificPopup.next();
            return;
        };
        magnificPopup.prev();
    });

    $('.mfp-close').on('click', function() {
        magnificPopup.close();
    });

    $('.lightbox-manual-close-trigger').click(function(event) {
        e.preventDefault();
        $.magnificPopup.close();
    });

    /*******************************************/
    // Tabs
    $('.js-tab-triggers a').on('click', function(e) {
        e.preventDefault();
        $('.js-tab-triggers a').not(this).removeClass('active');
        $(this).addClass('active');
        var getTarget = $(this).attr('href');
        $('.tab-content').not(getTarget).hide();
        $(getTarget).show();
    });

    /*******************************************/

});


/*******************************************/
$.fn.toggleHTML = function(t1, t2) {
    if (this.html() == t1) this.html(t2);
    else this.html(t1);
    return this;
};


/*******************************************/
//Main Menu
function slideMenu(action){ 
    var $menu = $('#mobile-menu') 
    if (!action) { toggle(); return }
    if (action == "open") { open(); return }
    if (action == "close") { close(); return }

    function open(){
        $("body").addClass('open-menu');
        $menu.attr('status', 'open');
    }
    function close(){
        $("body").removeClass('open-menu');
        $menu.attr('status', 'closed');
    }
    function toggle(){
        var status =  $menu.attr('status');
        if (status == "open"){ close(); return }
        if (status == "closed"){ open(); return }
    }
}

$('#mobile-menu li').each(function(){
    $(this).has("ul").addClass('mobile-menu-parent');
});

$( ".mobile-menu-parent .icon" ).on("click", function(){
    $(this).siblings('ul').slideToggle(150);
});

//prevent slide up
$(".mobile-menu-parent").on("click", "ul", function(event){
    event.stopPropagation()
})

/*******************************************/
// Header Highlighter
$(document).ready(function() {
    var headerHref = "#site-header";
    var mobileHref = "#mobile-menu";
    var myURL = "/" + location.pathname.split('/')[1];

    $(headerHref + ' li').each(function(){
        if ($(this).find('a').attr('href') == myURL) {
            $(this).find('a').addClass("selected")
            return
        }
    })

    $(mobileHref + ' li').each(function(){
        if ($(this).find('a').attr('href') == myURL) {
            $(this).find('a').addClass("selected")
            return
        }
    })
});

/*******************************************/



